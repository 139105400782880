import React, { useState,useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate ,HashRouter } from 'react-router-dom';
import SignIn from './Pages/SignIn/SignIn';
import Query from './Pages/Query/Query';
import DocumentSearch from './Pages/DocumentSearch/DocumentSearch';
import QueryHistory from './Pages/QueryHistory/QueryHistory';
import QueryShare from './Pages/QueryShare/QueryShare';
import Faqs from './Pages/Faqs/Faqs';
import CapabilitiesAndLimitations from './Pages/CapabilitiesAndLimitations/CapabilitiesAndLimitations';
import { AppProvider } from './Includes/AppContext';
import { PathProvider } from './Includes/PathContext';
import Department from './Pages/AdminConsole/Department/Department';
import User from './Pages/AdminConsole/User/User';
import DocumentManager from './Pages/AdminConsole/DocumentManager/DocumentManager';
import FeedbackReview from './Pages/AdminConsole/FeedbackReview/FeedbackReview.jsx';
import PDFViewer from './Pages/Pdfview/PDFViewer.jsx';
import Error from './Pages/Error/Error.js';
import NewPassword from './Pages/SignIn/NewPassword.jsx';
import CalenderReviewMeeting from './Pages/CmdDashBoard/Pages/CalenderReviewMeeting/CalenderReviewMeeting.jsx';
import CmdDepartment from './Pages/CmdDashBoard/Pages/Department/Department.jsx';
import DepartmentLanding from './Pages/CmdDashBoard/Pages/Department/DepartmentLanding.jsx';
import AreaLanding from './Pages/CmdDashBoard/Pages/Department/Area.jsx';
import ReviewMeetings from './Pages/CmdDashBoard/Pages/ReviewMeetings/ReviewMeetings.jsx';
import ShortlinkLanding from './Pages/CmdDashBoard/Pages/ReviewMeetings/ShortlinkLanding.jsx';
import CriticalActivity from './Pages/CmdDashBoard/Pages/CriticalActivitty/CriticalActivities.jsx';
import RedList from './Pages/CmdDashBoard/Pages/RedList/RedList.jsx';
import AdminCmdLink from './Pages/AdminConsole/CmdLink/CmdLink.jsx';
import Notifications from './Pages/Notification/Notification.jsx';
import Activities from './Pages/Activities/Activities.jsx';
import Support from './Pages/CmdDashBoard/Pages/Support/Support.jsx';
function App() {
   
  const [userData, setUserData] = useState(() => {
    const storedData = localStorage.getItem("userdata");
    return storedData ? JSON.parse(storedData) : null;
  });

  const checkUserData = () => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      return <Navigate to="/" />;
    }
    setUserData(JSON.parse(storedData));
    return null;
  };

  //local
  // window.constants = {
  //   api_url: "http://localhost/deep-probe-mcl-lumen-api-v2", 
  //   embeded_api_clientId : '43713c09008540cbb09789c99c0c0597', 
  //   search_method_name : 'searchquerymock',
  //   share_url : "http://localhost:3000/" 
  // };
  
  //live
  window.constants = {
    api_url: "https://api-soochnakosh-mcl.zeonai.com", 
    embeded_api_clientId : '3325565a44b14279a112aaa641b89b4e', 
    search_method_name : 'searchquery', 
    share_url : "https://www.soochnakosh-mcl.zeonai.com" 
  };

  //staging
  // window.constants = {
  //   api_url: "https://api-soochnakosh-staging.zeonai.com", 
  //   embeded_api_clientId : '6009575d8eed4328a50b66953b04e26b', 
  //   search_method_name : 'searchquerymock', 
  //   share_url : "https://www.soochnakosh-staging.zeonai.com" 
  // };
  
  
  useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      return;
    }

    setUserData(JSON.parse(storedData));

    // If the user is logged in and tries to access the login page, redirect to the dashboard
    // if (window.location.pathname === '/' && window.location.search !== '?logout=true') {
    //   window.location.replace('/query');
    // }
  }, []);
  const PrivateRoute = ({ element }) => {
    return userData ? element : checkUserData();
  };

  return (
    <div className="App">
      <AppProvider>
        <Router>
          <PathProvider>
            <Routes>
              <Route path='/' element={<SignIn />} />
              <Route
                path='/query-share'
                element={<PrivateRoute element={<QueryShare />} />}
              />
              <Route path='/query' element={<PrivateRoute element={<Query />} />} />
              <Route
                path='/query/:conversation_id'
                element={<PrivateRoute element={<Query />} />}
              />
              <Route path='/new-query' element={<PrivateRoute element={<Query />} />} />
              <Route
                path='/document-search'
                element={<PrivateRoute element={<DocumentSearch />} />}
              />
             <Route path="/pdf-viewer" element={<PrivateRoute element={<PDFViewer />} />} />
              <Route
                path='/query-history'
                element={<PrivateRoute element={<QueryHistory />} />}
              />
              <Route
                path='/activities'
                element={<PrivateRoute element={<Activities />} />}
              />
              <Route
                path='/notifications'
                element={<PrivateRoute element={<Notifications />} />}
              />
              <Route path='/faq' element={<PrivateRoute element={<Faqs />} />} />
              <Route
                path='/capabilities-limitations'
                element={<PrivateRoute element={<CapabilitiesAndLimitations />} />}
              />
              <Route
                path='*'
                element={<PrivateRoute element={<Error />} />}
              />
              <Route path="/admin-console" element={<PrivateRoute element={<Department />} />} />
              <Route
                path="/admin-console/department"
                element={<PrivateRoute element={<Department />} />}
              />
                <Route
                path="/admin-console/general-info"
                element={<PrivateRoute element={<AdminCmdLink />} />}
              />
              <Route path='/admin-console/user' element={<PrivateRoute element={<User />} />} />
              <Route
                path='/admin-console/userDetails'
                element={<PrivateRoute element={<User />} />}
              />
              <Route
                path="/admin-console/document-manager"
                element={<PrivateRoute element={<DocumentManager />} />}
              />
              <Route
                path="/admin-console/feedback-review"
                element={<PrivateRoute element={<FeedbackReview />} />}
              />
              <Route
                path="/setnew-password"
                element={<PrivateRoute element={<NewPassword />} />}
              />
              <Route
                path="/cmd-dashboard/calendar"
                element={<PrivateRoute element={<CalenderReviewMeeting />} />}
              />
              <Route
                path="/cmd-dashboard/review-meetings"
                element={<PrivateRoute element={<ReviewMeetings />} />}
              />
              <Route
                path="/cmd-dashboard/review-meetings-link"
                element={<ShortlinkLanding />}
              />
              <Route
                path="/cmd-dashboard/critical-activity"
                element={<PrivateRoute element={<CriticalActivity />} />}
              />
              <Route
                path="/cmd-dashboard/red-list"
                element={<PrivateRoute element={<RedList />} />}
              />
              <Route
                path="/cmd-dashboard/support"
                element={<PrivateRoute element={<Support />} />}
              />
              <Route
                path="/cmd-dashboard/departments"
                element={<PrivateRoute element={<DepartmentLanding />} />}
              />
              <Route
                path="/cmd-dashboard/area"
                element={<PrivateRoute element={<AreaLanding />} />}
              />
              <Route
                path="/cmd-dashboard/department/:id"
                element={<PrivateRoute element={<CmdDepartment />} />}
              />
            </Routes>
          </PathProvider>
        </Router>
      </AppProvider>
    </div>
  );
}

export default App;
